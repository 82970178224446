/*eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
// import axios from "axios";
import Cookies from "js-cookie";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { List } from "@mui/material";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import Button from "components/MDButton";
// import CustomLoading from "components/CustomComponents/CustomLoading";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav } from "context";

// project assets
import profilePicture from "assets/images/team-3.jpg";

import { useAuth } from "components/CustomComponents/authProviderContext";

// import colors from "assets/theme/base/colors";

function Sidenav({ color = "primary", brand = "", brandName, routes, ...rest }) {
  const userPerms = localStorage.getItem("userPerms") ?? "";
  const { loading, user, logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [openNestedCollapseL3, setOpenNestedCollapseL3] = useState(false);
  const [loggedUserDetails, setLoggedUserDetails] = useState({
    username: "Brooklyn Alice",
    picture: profilePicture,
    roles: null,
    permissions: null,
    view: null,
  });
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const items = pathname.split("/").slice(1);
  const itemName = items[items.length - 1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(items[0]);
    setOpenNestedCollapse(items[1]);
    setOpenNestedCollapseL3(items[2]);
  }, [location]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [location]);

  useEffect(() => {
    let mounted = true;
    const getUserDetails = () => {
      let viewData = {
        username: user["username"].split(" ")[0] || user["nickname"],
        roles: user[process.env.REACT_APP_AUTH0_USER_ROLES_KEY] ?? [],
        permissions: userPerms?.split(",") ?? [],
        picture: user["picture"] || profilePicture,
        view: null,
      };
      if (viewData.roles?.join().includes(process.env.REACT_APP_ADMIN_ROLE)) {
        viewData.view = "ADMIN_VIEW";
        setLoggedUserDetails(viewData);
      } else {
        viewData.view = "ADMIN_VIEW"; //USER_VIEW
        setLoggedUserDetails(viewData);
      }
    };

    if (mounted && !loading) getUserDetails();
    return () => (mounted = false);
  }, [loading]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, icon, route, key, href, collapse, permissions }) => {
      if (collapse) {
        let isRenderCollapse = collapse.filter(({ permissions }) => {
          if (permissions != null) {
            let hasPermissions = null;
            if (Array.isArray(permissions)) {
              hasPermissions = permissions.filter((p) => {
                if (loggedUserDetails.permissions && Array.isArray(loggedUserDetails.permissions)) {
                  return loggedUserDetails.permissions.includes(p);
                } else return false;
              });
            }
            if (hasPermissions.length > 0) return true;
            else return false;
          } else {
            return true;
          }
        });
        if (isRenderCollapse.every((e) => e == null)) {
          return null;
        } else {
          return (
            <List key={`list-${key}`}>
              <SidenavItem
                key={key}
                icon={icon}
                color={color}
                name={name}
                active={key === items[2] ? "isParent" : false}
                open={openNestedCollapseL3 === key}
                onClick={({ currentTarget }) =>
                  openNestedCollapseL3 === key &&
                  currentTarget.classList.contains("MuiListItem-root")
                    ? setOpenNestedCollapseL3(false)
                    : setOpenNestedCollapseL3(key)
                }
              >
                {renderNestedCollapse(collapse)}
              </SidenavItem>
            </List>
          );
        }
      } else {
        let hasPermissions = null;
        if (permissions && Array.isArray(permissions)) {
          hasPermissions = permissions.filter((p) => {
            if (loggedUserDetails.permissions && Array.isArray(loggedUserDetails.permissions)) {
              return loggedUserDetails.permissions.includes(p);
            } else return false;
          });
        }
        if (permissions == undefined || (hasPermissions != null && hasPermissions.length > 0)) {
          return href ? (
            <Link
              key={key}
              href={href}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <List>
                <SidenavItem icon={icon} color={color} name={name} nested />
              </List>
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <List key={`list-${key}`}>
                <SidenavItem
                  icon={icon}
                  color={color}
                  name={name}
                  active={route === pathname}
                  nested
                />
              </List>
            </NavLink>
          );
        } else return null;
      }
    });

    return template;
  };

  // Render all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, icon, collapse, route, href, key, permissions }) => {
      let returnValue;
      if (collapse) {
        // check if there are any routes to render
        const nestedCollapse = renderNestedCollapse(collapse);
        if (Array.isArray(nestedCollapse) && nestedCollapse.every((e) => e == null)) {
          // nothing to render return null
          returnValue = null;
        } else {
          if (loggedUserDetails.view === "USER_VIEW") {
            // return routes that needs to be rendered
            returnValue = (
              <div key={`${key}-container`}>
                <MDTypography
                  key={key}
                  color={textColor}
                  display="block"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="uppercase"
                  pl={3}
                  mt={2}
                  mb={1}
                  ml={1}
                >
                  {name}
                </MDTypography>
                {nestedCollapse}
              </div>
            );
          } else {
            returnValue = (
              <SidenavItem
                icon={icon}
                key={key}
                color={color}
                name={name}
                active={key === items[1] ? "isParent" : false}
                open={openNestedCollapse === key}
                onClick={({ currentTarget }) =>
                  openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                    ? setOpenNestedCollapse(false)
                    : setOpenNestedCollapse(key)
                }
              >
                {nestedCollapse}
              </SidenavItem>
            );
          }
        }
      } else {
        let hasPermissions = null;
        if (permissions && Array.isArray(permissions)) {
          hasPermissions = permissions.filter((p) => {
            if (loggedUserDetails.permissions && Array.isArray(loggedUserDetails.permissions)) {
              return loggedUserDetails.permissions.includes(p);
            } else return false;
          });
        }
        if (permissions == undefined || (hasPermissions != null && hasPermissions.length > 0)) {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <List>
                <SidenavItem icon={icon} color={color} name={name} active={key === itemName} />
              </List>
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <List>
                <SidenavItem icon={icon} color={color} name={name} active={key === itemName} />
              </List>
            </NavLink>
          );
        } else {
          returnValue = null;
        }
      }
      if (returnValue != null) {
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      } else return returnValue;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse name={name} icon={icon} noCollapse={noCollapse} />
            </Link>
          );
        }
        // MAY NOT WORK AS REQUIRED
        else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === items[0]}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else if (collapse) {
          // check if there are any routes to render
          const nestedCollapse = renderCollapse(collapse);
          if (Array.isArray(nestedCollapse) && nestedCollapse.every((e) => e == null)) {
            // nothing to render return null
            returnValue = null;
          } else {
            if (loggedUserDetails.view === "USER_VIEW") {
              returnValue = nestedCollapse;
            } else {
              returnValue = (
                <SidenavCollapse
                  key={key}
                  name={name}
                  icon={icon}
                  active={key === items[0]}
                  open={openCollapse === key}
                  onClick={() =>
                    openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                  }
                >
                  {nestedCollapse}
                </SidenavCollapse>
              );
            }
          }
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        if (!transparentSidenav && !whiteSidenav) {
          returnValue = (
            <Divider
              key={key}
              sx={{
                opacity: 0.3,
                backgroundImage:
                  "linear-gradient(to right, rgba(52, 71, 103, 0), #ffffff, rgba(52, 71, 103, 0)) !important",
              }}
            />
          );
        } else {
          returnValue = <Divider key={key} sx={{ opacity: 0.4 }} />;
        }
      }

      return returnValue;
    }
  );

  const handleLogout = () => {
    Cookies.remove("mfaSessionId");
    localStorage.removeItem("userPerms");
    logout();
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            mx: "auto",
          }}
        >
          {"Are you sure?"}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="gradient"
            color="light"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button size="small" variant="gradient" color="primary" onClick={handleLogout}>
            Yes, logout!
          </Button>
        </DialogActions>
      </Dialog>
      <SidenavRoot
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox pt={3} pb={1} px={4} textAlign="center">
          <MDBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="body" fontSize="16px" color="secondary">
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox component={NavLink} to="/" textAlign="center">
          {!miniSidenav && brand && (
            <MDBox component="img" src={brand} alt="Brand" width="182px" height="71px" />
          )}
          {miniSidenav && brand && (
            <MDBox component="img" src={brand} alt="Brand" width="70px" height="27px" />
          )}
          <MDBox width="100%" sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })} />
        </MDBox>
        {!transparentSidenav && !whiteSidenav ? (
          <Divider
            sx={{
              opacity: 0.3,
              backgroundImage:
                "linear-gradient(to right, rgba(52, 71, 103, 0), #ffffff, rgba(52, 71, 103, 0)) !important",
            }}
          />
        ) : (
          <Divider />
        )}
        <menu>
          <SidenavCollapse
            key={"profile"}
            // TODO : CREARTE A FUNCTION
            name={loggedUserDetails.username}
            icon={
              <img
                src={loggedUserDetails.picture}
                height={"36px"}
                width={"36px"}
                style={{ borderRadius: "50%" }}
                alt="User Profile"
              />
            }
            active={items[0] === "profile"}
            open={openCollapse === "profile"}
            onClick={() =>
              openCollapse === "profile" ? setOpenCollapse(false) : setOpenCollapse("profile")
            }
          >
            <SidenavList key={"profile"}>
              <SidenavItem
                icon={
                  <Icon
                    sx={(theme) => {
                      if ((!whiteSidenav && !transparentSidenav) || itemName === "profile")
                        return {
                          color: theme.palette.white.main,
                          ml: "5px",
                        };
                      return {
                        color: theme.palette.dark.main,
                        ml: "5px",
                      };
                    }}
                    fontSize="medium"
                  >
                    logout_rounded
                  </Icon>
                }
                onClick={() => {
                  setOpen(true);
                }}
                color={color}
                name={"Logout"}
                active={itemName === "logout"}
              />
              <li style={{ listStyle: "none" }}>
                <NavLink to={"/profile"} key={"profile"} sx={{ textDecoration: "none" }}>
                  <ul>
                    <SidenavItem
                      icon={
                        <Icon
                          sx={(theme) => {
                            if ((!whiteSidenav && !transparentSidenav) || itemName === "profile")
                              return {
                                color: theme.palette.white.main,
                                ml: "5px",
                              };
                            return {
                              color: theme.palette.dark.main,
                              ml: "5px",
                            };
                          }}
                          fontSize="medium"
                        >
                          manage_accounts
                        </Icon>
                      }
                      color={color}
                      name={"Profile"}
                      active={itemName === "profile"}
                      nested
                    />
                  </ul>
                </NavLink>
              </li>
            </SidenavList>
          </SidenavCollapse>
          {renderRoutes}
        </menu>
      </SidenavRoot>
    </>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "inherit",
    "white",
    "primary",
    "primaryLight",
    "secondary",
    "purpleShade",
    "info",
    "success",
    "successDark",
    "warning",
    "error",
    "errorDark",
    "light",
    "dark",
    "text",
    "black",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
