/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route
*/
import React, { lazy } from "react";

// Material Dashboard 2 PRO React layouts
// const Analytics = lazy(() => import("views/pages/Reports"));
const Bookmakers = lazy(() => import("views/pages/Bookmakers"));
const BookmakerDetailsPage = lazy(() => import("views/pages/Bookmakers/Components/DetailsPage"));
const Syndicates = lazy(() => import("views/pages/Syndicates"));
const SyndicatesDetailsPage = lazy(() => import("views/pages/Syndicates/Components/DetailsPage"));
const Reports = lazy(() => import("views/pages/Reports"));
const AccountSettings = lazy(() => import("views/pages/account/settings"));

// const SignInIllus = lazy(() => import("views/authentication/sign-in/basic/illustration"));
const SignInBasic = lazy(() => import("views/authentication/sign-in/basic"));
const ErrorPage404 = lazy(() => import("views/pages/error-pages/ErrorPage404"));

const protectedRoutes = [
  { type: "divider", key: "divider-0" },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   collapse: [
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/dashboards/analytics",
  //       component: <Analytics />,
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Bookmakers",
    key: "bookmakers",
    route: "/bookmakers",
    icon: "person",
    component: <Bookmakers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Syndicates",
    key: "syndicates",
    route: "/syndicates",
    icon: "groups",
    component: <Syndicates />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: "/reports",
    icon: "insights",
    component: <Reports />,
    noCollapse: true,
  },
];

const otherProtectedRoutes = [
  {
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: "manage_accounts",
    component: <AccountSettings />,
  },
  {
    name: "Edit Bookmakers",
    key: "edit",
    route: "/bookmakers/edit",
    icon: "person",
    component: <BookmakerDetailsPage />,
  },
  {
    name: "Edit Syndicates",
    key: "edit",
    route: "/syndicates/edit",
    icon: "groups",
    component: <SyndicatesDetailsPage />,
  },
];

const publicRoutes = [
  {
    name: "Page Not Found",
    key: "page-not-found",
    route: "/page-not-found",
    component: <ErrorPage404 />,
  },
];
const authRoutes = [
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: <SignInBasic />,
  },
  {
    name: "Login2",
    key: "login2",
    route: "/login2",
    component: <SignInBasic />,
  },
];

export { publicRoutes, authRoutes, otherProtectedRoutes };
export default protectedRoutes;
