import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import PropTypes from "prop-types";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUserAndToken = () => {
    const token = localStorage.getItem("access_token");
    const currentTime = Date.now() / 1000; // Current time in seconds since Unix Epoch
    try {
      if (typeof token === "string") {
        const decoded = jwtDecode(token);
        const tokenExpired = decoded.exp ? decoded.exp < currentTime : true;
        if (!tokenExpired) {
          return { tokenData: decoded, apiAccessToken: token };
        } else {
          return { tokenData: null, apiAccessToken: null };
        }
      } else {
        return { tokenData: null, apiAccessToken: null };
      }
    } catch (error) {
      // Handle error if the token is not valid JSON Web Token
      console.error(error.message);
      return { tokenData: null, apiAccessToken: null };
    }
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    window.location.href = process.env.REACT_APP_LOGIN_PAGE;
  };

  useEffect(() => {
    const { tokenData, apiAccessToken } = getUserAndToken();
    setUser(tokenData);
    setAccessToken(apiAccessToken);
    setLoading(false);
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API}/validate-access-token`, {
          headers: {
            authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        });
        if (!res.data?.isSuccessful) {
          logout();
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!loading && accessToken) {
      validateToken();
    }
  }, [loading]);

  return (
    <AuthContext.Provider value={{ user, accessToken, loading, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
